import * as styles from './FAQContent.module.scss'

import React from 'react'
import { Link } from 'gatsby'

export const FAQContent: React.FC = () => {
  return (
    <>
      <h2 className={styles.heading}>The Lucky Paper Commander Map</h2>
      <p className={styles.intro}>
        This map visualizes every one of the over 2.5 million Commander decks on{' '}
        <a href="http://edhrec.com" target="_blank" rel="noreferrer">
          EDHREC
        </a>{' '}
        in one virtual space.
      </p>
      <p className={styles.intro}>
        Decks are algorithmically organized based on their cards. Decks that
        share more cards are closer together.
      </p>

      <div className={styles.divider} />

      <div className={styles.question}>
        <h4 className={styles.questionHeading}>Do the axes mean anything?</h4>
        <p>
          No. This map is more like a globe, cut and flattened out into a 2D
          picture in uninterpretable ways. If that globe also existed in 20,000
          dimensions rather than three.
        </p>
      </div>

      <div className={styles.question}>
        <h4 className={styles.questionHeading}>How is the map generated?</h4>
        <p>
          Positions are determined by an algorithm called UMAP, used by data
          scientists to understand multi-dimensional data. The algorithm only
          considers the names of the cards in each deck and knows nothing a
          priori about themes, tribes, or Commanders.
        </p>
      </div>

      <ul className={styles.links}>
        <li>
          <Link
            className={styles.link}
            to="/articles/an-introduction-to-the-commander-map/"
          >
            Read more about what the map is →
          </Link>
        </li>

        <li>
          <Link
            className={styles.link}
            to="/articles/mapping-the-cube-landscape/"
          >
            Dive into the math on a technical level →{' '}
          </Link>
        </li>

        <li>
          <Link className={styles.link} to="/resources/cube-map/">
            A map of all Cubes on Cube Cobra →
          </Link>
        </li>

        <li>
          <Link
            className={styles.link}
            to="/podcast/introducing-the-commander-map/"
          >
            Listen to how we created the map on Lucky Paper Radio →
          </Link>
        </li>
      </ul>

      <div className={styles.question}>
        <h4 className={styles.questionHeading}>Have another question?</h4>
        <p>
          You can reach us at{' '}
          <a href="mailto:mail@luckypaper.co">mail@luckypaper.co</a> or{' '}
          <a
            href="https://twitter.com/luckypaperMtG"
            target="_blank"
            rel="noreferrer"
          >
            @luckypapermtg
          </a>{' '}
          on Twitter.
        </p>
      </div>

      <div>
        <p>
          The map was created by Jett Crowdis&rsquo;s data analysis savvy and{' '}
          <a
            href="https://twitter.com/ahmattox"
            target="_blank"
            rel="noreferrer"
          >
            Anthony Mattox
          </a>
          &rsquo;s web development for the fun and delight of the game.
        </p>
      </div>
    </>
  )
}
